import { ready } from './utils'
import OverflowMenu from './overflow-menu';

window.Theme = {
  init: function () {
    return ready(() => {
      document.querySelectorAll('.overflow-menu').forEach((item) => {
        return OverflowMenu.init(item);
      });
    });
  }
};

window.Theme.init();
