import {
  computePosition,
  flip,
  shift,
  offset
} from '@floating-ui/dom';

const OverflowMenu = {
  init(el) {
    this.el = el;
    this.boundCloseOnOutsideClick = this.closeOnOutsideClick.bind(this);

    this.trigger().addEventListener('click', e => {
      e.stopPropagation();

      if(this.isOpened()) {
        return this.close();
      }
      return this.open();
    });
  },

  trigger() {
    return this.el.querySelector('.overflow-menu-trigger');
  },

  content() {
    return this.el.querySelector('.overflow-menu-content');
  },

  isOpened() {
    return this.content().style.display === 'block';
  },

  closeOnOutsideClick(e) {
    if (!this.el.contains(e.target)) {
      this.close();
    }
  },

  update() {
    return computePosition(this.trigger(), this.content(), {
      placement: 'top',
      middleware: [offset(8), flip(), shift()],
    }).then(({x, y}) => {
      Object.assign(this.content().style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  },

  open() {
    this.content().style.display = 'block';
    document.addEventListener('click', this.boundCloseOnOutsideClick);

    this.update();
  },

  close() {
    this.content().style.display = '';
    document.removeEventListener('click', this.boundCloseOnOutsideClick);
  }
}

export default OverflowMenu;
